const toLocalMidnightISOString = (date: Date): string => {
  const localMidnight = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  return localMidnight.toISOString();
};

export const getRangeValues = (values: { from: Date; to: Date }) => {
  const fromMidnight = toLocalMidnightISOString(values.from);
  const toMidnight = toLocalMidnightISOString(values.to);

  if (fromMidnight === toMidnight) {
    const from = new Date(values.from);
    from.setHours(0, 0, 0, 0);

    const to = new Date(values.to);
    to.setHours(23, 59, 59, 999);

    return {
      from: from.toISOString(),
      to: to.toISOString(),
    };
  }

  return {
    from: fromMidnight,
    to: toMidnight,
  };
};
