export const IconDevice = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="11" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4167 11.6667H24.5833C25.5038 11.6667 26.25 12.4129 26.25 13.3334V26.6667C26.25 27.5872 25.5038 28.3334 24.5833 28.3334H15.4167C14.4962 28.3334 13.75 27.5872 13.75 26.6667V13.3334C13.75 12.4129 14.4962 11.6667 15.4167 11.6667ZM19.1667 25.35C19.1667 25.8103 19.5398 26.1834 20 26.1834C20.4602 26.1834 20.8333 25.8103 20.8333 25.35C20.8333 24.8898 20.4602 24.5167 20 24.5167C19.5398 24.5167 19.1667 24.8898 19.1667 25.35Z"
      fill="#739B67"
    />
  </svg>
);
