import styled from "@emotion/styled/macro";

export const MainContainer = styled.div`
  margin-bottom: 56px;
  @media (max-width: 979px) {
    margin-bottom: 24px;
  }
`;

export const Container = styled.div`
  margin-bottom: 20px;
  display: flex;

  align-items: center;
  column-gap: 6.5px;
  margin-top: 0;

  @media (max-width: 979px) {
    display: none;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: var(--main-color-text-title);
  margin: 0;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`;

export const Item = styled.div`
  background: var(--main-color-bg-widgets);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  padding: 12px 8px;
  row-gap: 12px;
  min-width: 100px;

  @media (max-width: 979px) {
    flex-direction: row;
    column-gap: 4px;
    justify-content: center;
    padding: 12px 0;
  }
`;

export const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  overflow: hidden;
  background-position: center;
  background-size: 200%;
`;

export const ItemReduction = styled.p`
  margin: 0;
  color: #7e7f7a;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;

  @media (max-width: 979px) {
    display: none;
  }
`;

export const ItemValue = styled.p`
  margin: 0;

  font-weight: 400;
  color: #2d3227;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.075px;
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const BlockIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;
`;

export const ItemsBlock = styled.div`
  overflow-y: scroll;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

  @media (max-width: 979px) {
    column-gap: 8px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4px;
  }
`;

export const ItemsBlockWrapper = styled.div`
  display: grid;

  @media (max-width: 600px) {
    grid-template-columns: 1fr 24px;
    column-gap: 4px;
  }
`;

export const EmptyIcon = styled.div`
  height: 4px;
  width: 7px;
`;
