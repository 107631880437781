import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ChatAttachmentDto } from "api/notification";

interface ReplyMessageProps {
  text?: string;
  isClientMessage?: boolean;
  maxWidth?: string;
}

export const ReplyMessage: React.FC<ReplyMessageProps> = ({
  text,
  isClientMessage = false,
  maxWidth = "",
}) => {
  return (
    <Box
      sx={{
        borderRadius: 6,
        padding: "4px 0px",
        backgroundColor: "rgba(120, 173, 105, 0.15)",
        width: "100%",
        maxWidth: maxWidth ? maxWidth : "auto",
      }}
    >
      <Box
        sx={{
          borderLeft: "#739B67 2px solid",
        }}
      >
        <Box sx={{ paddingLeft: 8 }}>
          <Typography
            sx={{
              color: "#739B67",
              fontSize: "12px",
              fontWeight: 300,
              lineHeight: "14px",
            }}
          >
            {isClientMessage ? "Вы" : "Оператор"}
          </Typography>

          <Typography
            sx={{
              color: "#454A3F",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {text ? text : "вложение"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
