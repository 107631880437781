import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { IconDevice } from "atoms/IconDevice/IconDevice";
import { Button, Loader } from "components";
import { useDispatch, useSelector } from "store";
import {
  allNotificationsActions,
  pushDisableAll,
} from "store/slices/allNotifications";
import notification from "../../../../images/notification.png";
import styles from "./index.module.css";

const Devices = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);
  const { devices, isLoadingDisable } = useSelector(
    (state) => state.allNotifications
  );

  const handleSubmit = () => dispatch(pushDisableAll());

  return (
    <>
      <p className={styles.title}>
        Отключить
        <br />
        Push-уведомления на этих устройствах ({devices.length})?
      </p>
      <p className={styles.description}>
        Вместо уведомлений вам будут приходить sms-сообщения на номер{" "}
        <span className={styles.number}>*** {user?.phone?.slice(-4)}</span>
      </p>
      <div className={styles.items}>
        {devices.map(({ deviceName, deviceId }) => (
          <div key={deviceId} className={styles.item}>
            <IconDevice />
            {deviceName}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button
          title="Отключить"
          variant="dangerous"
          onClick={handleSubmit}
          isLoading={isLoadingDisable}
        />
        <Button
          title="Отмена"
          variant="secondary"
          onClick={onClose}
          disabled={isLoadingDisable}
        />
      </div>
    </>
  );
};

const NoDevices = ({ onClose }: { onClose: () => void }) => {
  const { user } = useSelector((state) => state.profile);

  return (
    <>
      <img src={notification} className={styles.notificationIcon} />
      <p className={styles.noDeviceTitle}>
        Push-уведомления отключены
        <br />
        на всех устройствах
      </p>
      <p className={styles.noDeviceDescription}>
        Вместо уведомлений вам будут
        <br />
        приходить sms-сообщения
        <br />
        на номер{" "}
        <span className={styles.number}>
          {formatPhoneNumber(user?.phone || "")}
        </span>
      </p>
      <Button title="Понятно" variant="primary" onClick={onClose} />
    </>
  );
};

export const AllNotifications = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const { isLoadingDevices, devices } = useSelector(
    (state) => state.allNotifications
  );

  const handleClose = () => {
    onClose();
    dispatch(allNotificationsActions.resetStore());
  };

  if (isLoadingDevices) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {devices.length ? (
        <Devices onClose={handleClose} />
      ) : (
        <NoDevices onClose={handleClose} />
      )}
    </div>
  );
};
