import Box from "@mui/material/Box";
import { RefillBlock } from "components/RefillBlock";
import { useIsMobile } from "hooks/useIsMobile";
import { ReactNode, useState } from "react";
import {
  CARD_ACTION,
  blockedCard,
  cardActionsToggle,
  getStatusPin,
  resetPin,
  setScreen,
  unblockedCard,
  updateCardInfo,
  setCardError,
  setIsCardInfoOpen,
  getCardInfo,
} from "store/slices/cardsSlice";
import { CardDto, ResetPinResponse } from "../../api/account";
import { UnSuccess } from "../../pages/home/Cards/Limits/UnSuccess";
import { cardsSelector, useDispatch, useSelector } from "../../store";
import { setNewStatusCard, unLoading } from "../../store/slices/cardsSlice";
import theme from "../../theme";
import AfterConfirmInfo from "./CardModalContent/AfterConfirmInfo";
import { BlockConfirm } from "./CardModalContent/BlockConfirm";
import { ChangeNameCard } from "./CardModalContent/ChangeNameCard";
import LimitCard from "./CardModalContent/LimitCard";
import BlockedCardConfirm from "./assets/BlockedCardConfirm";
import BlockedCardConfirmMobile from "./assets/BlockedCardConfirmMobile";
import ChangePinConfirm from "./assets/ChangePinConfirm.jpg";
import ConfirmImage from "./assets/ConfirmImage.jpg";
import FrozenCardConfirm from "./assets/FrozenCardConfirm";
import UnfrozenIcon from "./assets/UnfrozenIcon.jpg";
import { UnSuccessStatusChange } from "pages/home/Cards/Limits/UnSuccessStatusChange";
import { Button } from "components/Button";
import { RefillErrorScreen } from "./CardModalContent/RefillErrorScreen";
import { checkAvailableVirtualCard } from "store/slices/virtualCardSlice";
import { SHOW_VIRTUAL_CARD } from "utils/constants";
import { AccountInfo } from "pages/cards/modal/AccountModal/AccountInfo";
import { CardModal } from "components/CardModal/CardModal";

const {
  LOST,
  UNFROZEN,
  CHANGE_PIN,
  CHANGE_PIN_INFO,
  CHANGE_NAME_INFO,
  CHANGE_NAME,
  UNSUCCESS,
  UNSUCCESS_STATUS_CHANGE,
  REFERRAL,
  REFILL,
  LIMIT,
  REFILL_ERROR,
  ACCOUNT_INFO,
  CHANGE_CARD_NAME,
} = CARD_ACTION;

interface IDataForModal {
  id?: number;
  titleModal?: string;
  title?: string;
  srcJpg?: string;
  icon?: ReactNode;
  iconMobile?: ReactNode;
  textBtn?: string;
  subtitle?: string;
  subtitleConfirm?: string;
  subtitleMob?: string;
  titleConfirm?: string;
  textAlign?: string;
}
const errorCodes = {
  errorCode: "CHANGE_CARD_STATUS_ERROR_CODE",
  responseErrorCode: "CHANGE_CARD_STATUS_RESPONSE_ERROR_CODE",
};

const dataForModal: Record<string, IDataForModal> = {
  [REFERRAL]: {
    id: 0,
    titleModal: "Заморозка карты",
    title: "Заморозить карту",
    icon: <FrozenCardConfirm />,
    iconMobile: <FrozenCardConfirm />,
    textBtn: "Заморозить карту",
    subtitle: `Операции по карте будут приостановлены.\nВы сможете разморозить \nкарту в любое время`,
  },
  [LOST]: {
    id: 1,
    titleModal: "Блокировка карты",
    title: "Заблокировать карту",
    icon: <BlockedCardConfirm />,
    iconMobile: <BlockedCardConfirmMobile />,
    textBtn: "Заблокировать карту",
    subtitle: `Разблокировать карту будет невозможно.\n Для получения новой карты нужно обратиться\n в обслуживающее полевое учреждение`,
    textAlign: "left",
  },
  [CHANGE_PIN]: {
    id: 2,
    titleModal: "Сменить пин-код карты",
    title: "Сменить пин-код карты",
    srcJpg: ChangePinConfirm,
    textBtn: "Сменить пин-код",
    subtitle:
      "Вы уверены, что хотите сменить пин-код карты? Новый пин-код будет отправлен в СМС сообщении на номер телефона, привязанный к карте.",
    subtitleConfirm:
      "Если у вас возникли вопросы или вам не пришел пин-код, обратитесь в чат поддержки или по номеру телефона",
  },
  [CHANGE_NAME]: {
    id: 3,
    titleModal: "Изменение имени карты",
    title: "Изменение имени карты",
    titleConfirm: "Имя карты изменено",
    srcJpg: ConfirmImage,
    textBtn: "Изменить имя",
    subtitle: "Для карты придумайте новое имя",
    subtitleConfirm:
      "Вы можете изменить имя карты в любое удобное для вас время",
  },
  [UNFROZEN]: {
    id: 4,
    titleModal: "Разморозка карты",
    title: "Разморозить карту",
    srcJpg: UnfrozenIcon,
    textBtn: "Разморозить карту",
    subtitle:
      "Операции по карте будут восстановлены и вы снова сможете пользоваться картой",
  },
  [LIMIT]: {
    id: 5,
    titleModal: "Лимиты по карте",
    title: "Суточный лимит на",
  },
};

export const CardActions = () => {
  const [cardName, setCardName] = useState("");
  const [blockTypeAction, setBlockTypeAction] = useState("");
  const dispatch = useDispatch();
  const { screen, errorMessage } = useSelector(cardsSelector);
  const { isMobile } = useIsMobile();
  const payload = dataForModal[screen] || {};

  const {
    cards: { mainCard, isBlockLoading, chosenCardIndex, allCards },
    profile: { user },
  } = useSelector((state) => state);
  const { cardId, cardType }: CardDto = allCards[chosenCardIndex as number];

  const card = allCards[chosenCardIndex || 0];

  const onClose = () => {
    dispatch(setCardError({}));
    dispatch(setIsCardInfoOpen(0));
    dispatch(cardActionsToggle());
  };

  const onCloseStatusChange = () => {
    dispatch(setCardError({}));
    dispatch(setIsCardInfoOpen(0));
    dispatch(cardActionsToggle());
    dispatch(getCardInfo({ cardId: cardId as string }));
  };

  const handleSuccessBlock = (status) => {
    if (cardType === "VIRTUAL" && SHOW_VIRTUAL_CARD)
      dispatch(checkAvailableVirtualCard());
    dispatch(setNewStatusCard(status));
    dispatch(cardActionsToggle());
  };

  const handleConfirm = (cardAction: string | undefined) => {
    if (cardAction === CARD_ACTION.LOST) {
      const payload = {
        cardId,
        type: blockTypeAction,
      };
      // @ts-ignore
      dispatch(blockedCard(payload))
        .unwrap()
        .then(({ status }) => handleSuccessBlock(status))
        .catch(() => dispatch(setScreen(CARD_ACTION.UNSUCCESS)));
      return;
    }

    if (cardAction === "REFERRAL" || cardAction === CARD_ACTION.LOST) {
      const payload = {
        cardId,
        type: cardAction,
      };
      // @ts-ignore
      dispatch(blockedCard(payload))
        .unwrap()
        .then(({ status }) => handleSuccessBlock(status))
        .catch((res) => {
          const { code, title, subtitle } = res.response.data;
          dispatch(setCardError({ code, title, subtitle }));

          if (res.code === errorCodes.responseErrorCode) {
            dispatch(setScreen(UNSUCCESS_STATUS_CHANGE));
          } else {
            dispatch(setScreen(UNSUCCESS));
          }
        });
      return;
    }

    switch (cardAction) {
      case UNFROZEN:
        dispatch(unblockedCard({ cardId: mainCard?.cardId as string }))
          .unwrap()
          .then((res) => {
            dispatch(setNewStatusCard(res.status));
            dispatch(cardActionsToggle());
          })
          .catch(() => {
            dispatch(setScreen(UNSUCCESS));
          });
        return;
      case CHANGE_PIN:
        dispatch(resetPin({ cardId: mainCard?.cardId }))
          .unwrap()
          .then((res: ResetPinResponse) => {
            if (res.id) {
              setTimeout(() => {
                if (res.id != null) {
                  dispatch(getStatusPin(res.id));
                }
              }, 3000);
            } else {
              dispatch(unLoading(false));
              dispatch(setScreen(CARD_ACTION.CHANGE_PIN_INFO));
            }
          })
          .catch((err: any) => {
            dispatch(setScreen(CARD_ACTION.UNSUCCESS));
            return err;
          });
        return;
      case CHANGE_NAME:
        dispatch(
          updateCardInfo({
            cardUID: mainCard?.cardId as string,
            description: cardName,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(setScreen(CHANGE_NAME_INFO));
          })
          .catch((err: any) => {
            dispatch(setScreen(UNSUCCESS));
            return err;
          });
        return;
      default:
        alert("Нет таких значений");
    }
  };

  const screenConfirm = () => {
    if (
      screen === LOST ||
      screen === REFERRAL ||
      screen === CHANGE_PIN ||
      screen === CHANGE_NAME ||
      screen === UNFROZEN
    )
      return true;
  };

  const isDisabledBtn = (action: string | undefined) => {
    switch (action) {
      case LOST:
        if (!blockTypeAction) return true;
        break;
      case CHANGE_NAME:
        if (!cardName) return true;
        break;
      default:
        return false;
    }
  };

  const marginTopValue = (action: string | undefined) => {
    switch (action) {
      case REFERRAL:
      case UNFROZEN:
      case LOST:
      case CHANGE_NAME:
        return isMobile ? "52px" : "52px";
    }
  };

  const screens = {
    [LIMIT]: <LimitCard />,
    [LOST]: (
      <BlockConfirm
        payload={payload}
        foreverBlock={true}
        setBlockTypeAction={setBlockTypeAction}
      />
    ),
    [REFERRAL]: <BlockConfirm payload={payload} />,
    [CHANGE_PIN]: <BlockConfirm payload={payload} />,
    [CHANGE_NAME]: (
      <ChangeNameCard
        cardName={cardName}
        cardPan={mainCard?.cardPan}
        setCardName={setCardName}
      />
    ),
    [CHANGE_PIN_INFO]: (
      <AfterConfirmInfo
        payload={payload}
        isPin={true}
        cardPan={mainCard?.cardPan}
        phone={user?.phone}
      />
    ),
    [CHANGE_NAME_INFO]: <AfterConfirmInfo payload={payload} />,
    [UNSUCCESS]: <UnSuccess onClick={onClose} payload={errorMessage} />,
    [UNSUCCESS_STATUS_CHANGE]: (
      <UnSuccessStatusChange
        onClick={onCloseStatusChange}
        payload={errorMessage}
      />
    ),
    [UNFROZEN]: <BlockConfirm payload={payload} />,
    [REFILL]: <RefillBlock />,
    [REFILL_ERROR]: <RefillErrorScreen onClick={onClose} />,
    [ACCOUNT_INFO]: <AccountInfo />,
    [CHANGE_CARD_NAME]: (
      <CardModal
        name={card?.description}
        cardId={card?.cardId || ""}
        onClose={onClose}
      />
    ),
  };

  const isDisabled = isDisabledBtn(screen);

  return (
    <Box height="100%">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        paddingBottom={16}
        px={isMobile ? 16 : 32}
      >
        {screens[screen]}

        {screenConfirm() && (
          <Box display="grid" gap="10px" mt={marginTopValue(screen)}>
            <Button
              variant={screen === CARD_ACTION.LOST ? "dangerous" : "primary"}
              onClick={() => handleConfirm(screen)}
              isLoading={isBlockLoading}
              disabled={isDisabled}
              title={payload?.textBtn || ""}
            />
            <Button variant="secondary" onClick={onClose} title="Отмена" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
