import { Loader } from "components/Loader";
import classnames from "classnames";
import styles from "./index.module.css";

interface Props {
  variant: "primary" | "secondary" | "dangerous" | "text";
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
}

export const Button: React.FC<Props> = ({
  variant,
  onClick,
  title,
  isLoading,
  fullWidth = true,
  disabled,
  style,
  startIcon,
  endIcon,
  className,
}) => {
  const isDangerous = variant === "dangerous";

  const createCn = () => {
    switch (variant) {
      case "primary":
        return styles.primary;
      case "secondary":
        return styles.secondary;
      case "dangerous":
        return styles.dangerous;
      case "text":
        return styles.text;
    }
  };

  const cn = createCn();

  return (
    <button
      className={classnames(cn, className)}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      disabled={disabled}
      style={{
        ...style,
        pointerEvents: isLoading || disabled ? "none" : "auto",
        width: fullWidth && variant !== "text" ? "100%" : "fit-content",
      }}
    >
      {startIcon}
      {isLoading ? (
        <Loader size={25} color={isDangerous ? "#EAAEC0" : undefined} />
      ) : (
        title
      )}
      {endIcon}
    </button>
  );
};
