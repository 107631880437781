import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ChatMessageDto } from "api/notification";
import { IconReplyArrow } from "atoms/IconReplyArrow/IconReplyArrow";

export const ChatMessageMenu = ({
  message,
  user,
  onReply,
  maxWidth = "",
}: {
  message: ChatMessageDto | null;
  user: ChatMessageDto["user"];
  onReply: () => void;
  maxWidth: string;
}) => {
  if (message === null) return null;

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "202px",
        maxWidth: maxWidth,
        padding: "18px 16px",
        boxSizing: "border-box",
        borderRadius: "9px",
        zIndex: 200,
        fontSize: "13px",

        display: "flex",
        alignItems: "center",
        columnGap: 6,
      }}
      onClick={onReply}
    >
      <IconReplyArrow /> <span>Ответить</span>
    </Box>
  );
};
