import { authActions } from "../store/slices/auth";
import { chatActions } from "../store/slices/chat";
import { notificationActions } from "../store/slices/notifications";
import { profileActions } from "../store/slices/profile";
import { promotionActions } from "../store/slices/promotion";
import { systemActions } from "../store/slices/system";
import { cardsActions } from "../store/slices/cardsSlice";
import { historyActions } from "../store/slices/historySlice";
import { limitsActions } from "../store/slices/limitsSlice";
import { transferActions } from "../store/slices/transferSlice";
import { virtualCardActions } from "store/slices/virtualCardSlice";
import { allNotificationsActions } from "store/slices/allNotifications";
import { store } from "../store";

export function useResetStore() {
  const resetStore = () => {
    store.dispatch(authActions.resetStore());
    store.dispatch(chatActions.resetStore());
    store.dispatch(notificationActions.resetStore());
    store.dispatch(profileActions.resetStore());
    store.dispatch(promotionActions.resetStore());
    store.dispatch(systemActions.resetStore());
    store.dispatch(virtualCardActions.resetStore());
    store.dispatch(cardsActions.resetStore());
    store.dispatch(historyActions.resetStore());
    store.dispatch(limitsActions.resetStore());
    store.dispatch(transferActions.resetStore());
    store.dispatch(allNotificationsActions.resetStore());
  };

  return resetStore;
}
