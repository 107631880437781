import { pushApi } from "api";
import { PmpDeviceDto } from "api/notification";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface State {
  isLoadingDevices: boolean;
  isLoadingDisable: boolean;
  devices: PmpDeviceDto[];
}

const initialState: State = {
  isLoadingDevices: false,
  isLoadingDisable: false,
  devices: [],
};

export const allDevicesWithPush = createAsyncThunk(
  "allDevicesWithPush",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await pushApi.allDevicesWithPush("");

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pushDisableAll = createAsyncThunk(
  "pushDisableAll",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await pushApi.pushDisableAll("");

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const allNotificationsSlice = createSlice({
  name: "allNotifications",
  initialState,
  reducers: {
    resetStore: (state) => {
      state.isLoadingDevices = false;
      state.isLoadingDisable = false;
      state.devices = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allDevicesWithPush.pending, (state) => {
      state.isLoadingDevices = true;
    });
    builder.addCase(allDevicesWithPush.fulfilled, (state, { payload }) => {
      state.devices = payload as unknown as PmpDeviceDto[];
      state.isLoadingDevices = false;
    });
    builder.addCase(allDevicesWithPush.rejected, (state) => {
      state.isLoadingDevices = false;
    });
    builder.addCase(pushDisableAll.pending, (state) => {
      state.isLoadingDisable = true;
    });
    builder.addCase(pushDisableAll.fulfilled, (state, { payload }) => {
      state.devices = [];
      localStorage.removeItem("isPushOn");
      const event = new Event("pushDisableAll");
      window.dispatchEvent(event);
      state.isLoadingDisable = false;
    });
    builder.addCase(pushDisableAll.rejected, (state) => {
      state.isLoadingDisable = false;
    });
  },
});

export const allNotificationsActions = allNotificationsSlice.actions;
