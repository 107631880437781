import { Typography, useTheme, Box } from "@mui/material";
import { SkeletonContainer } from "components";
import { setIsOpenModal, transferCheck } from "store/slices/transferSlice";
import { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "store";
import { PayloadModal } from "./Transfers";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { WarningTransfer } from "components/WarningTransfer/WarningTransfer";
import { WarningTransferAlert } from "components/WarningTransferAlert/WarningTransferAlert";
import { ClickableHeader } from "./ClickableHeader";
import { WarningTransferAlertMobile } from "components/WarningTransferAlertMobile";
import { useIsMobile } from "hooks/useIsMobile";
import { Button } from "components/Button";
import ChosenCard from "./ChosenCard";
import IconInfo from "../../../../atoms/IconInfo/IconInfo";

export const TransferCheck = ({
  payloadModal,
  onClose,
  chooseCard,
  setChooseCard,
  onTab,
  isBlockedAlert,
}: {
  payloadModal: PayloadModal;
  onClose: () => void;
  chooseCard: boolean;
  setChooseCard: (value: boolean) => void;
  onTab: (value: number) => void;
  isBlockedAlert: boolean;
}) => {
  const {
    transfer: { createdTransfer, isLoading },
    cards: { mainCard, fromTransferCard },
  } = useSelector((state) => state);

  const theme = useTheme();
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();

  const [isShowWarningAlert, setIsShowWarningAlert] = useState(true);
  const [isAlert, setIsAlert] = useState(false);

  const {
    amount = 0,
    toCardPan = "",
    toClientBank = "",
    paymentToken = "",
    fee = 0,
    reason = "",
  } = createdTransfer || {};

  const transferHandler = () => {
    onTab(1);
    dispatch(transferCheck({ paymentToken }));
  };

  const goToHandler = () => {
    onTab(0);
    dispatch(setIsOpenModal(false));
  };

  useEffect(() => {
    if (fromTransferCard?.amount) {
      fromTransferCard?.amount < amount ? setIsAlert(true) : setIsAlert(false);
    }
  }, [fromTransferCard?.amount, mainCard, amount]);

  useLayoutEffect(() => {
    if (isBlockedAlert) setIsShowWarningAlert(false);
  }, [isBlockedAlert]);

  return (
    <>
      <SkeletonContainer width="400px" height="500px" isLoading={isLoading}>
        {chooseCard && (
          <ChosenCard
            setChooseCard={setChooseCard}
            payloadModal={payloadModal}
            onClose={onClose}
          />
        )}
        {!chooseCard && (
          <>
            <ClickableHeader onBack={() => goToHandler()} />
            <Box flexGrow="1">
              {isAlert && (
                <Box
                  display="flex"
                  flexDirection="column"
                  borderRadius="16px"
                  padding="18px"
                  gap={8}
                  bgcolor={theme.palette.orange.b50}
                  mb={32}
                >
                  <Box display="flex" gap={6}>
                    <IconInfo fill={theme.palette.orange.b500} />
                    <Typography
                      variant="text_5"
                      fontWeight={500}
                      color="orange.b500"
                    >
                      Недостаточно средств на карте
                    </Typography>
                  </Box>
                  <Typography
                    variant="text_4"
                    fontWeight={400}
                    color="gray.b500"
                  >
                    Пожалуйста, пополните счет или выберите другую карту
                  </Typography>
                </Box>
              )}
              {!isMobile && (
                <WarningTransferAlert
                  isOpen={isShowWarningAlert}
                  handler={() => setIsShowWarningAlert(false)}
                />
              )}

              {isMobile && (
                <WarningTransferAlertMobile
                  isOpen={isShowWarningAlert}
                  handler={() => setIsShowWarningAlert(false)}
                />
              )}
              <WarningTransfer />
              <Box
                display="flex"
                flexDirection="column"
                gap="24px"
                sx={{
                  margin: "12px 0 36px",
                  paddingTop: isMobile ? "125px" : "0px",
                }}
              >
                <InfoBlock
                  label="Карта списания"
                  value={
                    fromTransferCard?.cardPan
                      ? fromTransferCard.cardPan.slice(-4)
                      : null
                  }
                />
                <InfoBlock
                  label="Получатель"
                  value={
                    "************" + toCardPan.substring(toCardPan?.length - 4)
                  }
                />
                <InfoBlock label="Банк получателя" value={toClientBank} />
                <InfoBlock
                  label="Сумма перевода"
                  value={amount}
                  isCurrency={true}
                />
                <InfoBlock label="Комиссия" value={fee} isCurrency={true} />
                {reason && (
                  <InfoBlock label="Сообщение получателю" value={reason} />
                )}
              </Box>
            </Box>
            <Button
              variant="primary"
              onClick={transferHandler}
              isLoading={isLoading}
              title="Подтвердить перевод"
            />
          </>
        )}
      </SkeletonContainer>
    </>
  );
};

// TODO переместить в нужную папку
const InfoBlock = ({ label, value, isCurrency = false }) => (
  <Box display="flex" flexDirection="column">
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: 400,
        color: "var(--main-color-text-secondary)",
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        fontSize: "17px",
        fontWeight: 500,
        lineHeight: "20px",
      }}
    >
      {isCurrency ? formatCurrency(value, true) : value}
    </Typography>
  </Box>
);
