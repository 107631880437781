import { IconClose } from "atoms/IconClose/IconClose";
import {
  CloseButton,
  Container,
  Description,
  Title,
  TitleBlock,
  TitleContainer,
} from "./components";
import { IconErrorBiometry } from "atoms/IconErrorBiometry";
import { useState } from "react";
import { useIsMobile } from "hooks/useIsMobile";

export const WarningTransfer = () => {
  const { isMobile } = useIsMobile();
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <Container isMobile={isMobile}>
      <TitleBlock>
        <IconErrorBiometry width={20} height={20} />
        <TitleContainer>
          <Title>Перевод нельзя отменить</Title>
          <CloseButton
            onClick={() => {
              setIsVisible(false);
            }}
          >
            <IconClose color="#e57616" />
          </CloseButton>
        </TitleContainer>
      </TitleBlock>
      <Description>
        Проверьте внимательно реквизиты получателя, не переводите деньги
        незнакомым людям.
      </Description>
    </Container>
  );
};
