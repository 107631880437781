import styled from "@emotion/styled";
import { Box, Switch, SwitchProps, Typography } from "@mui/material";
import { profileActions } from "store/slices/profile";
import {
  biometryRegConfirm,
  checkIsBiometryKeyExist,
  getBiometryCredentials,
  regBiometryToken,
} from "store/slices/auth";
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { useEffect, useState } from "react";
import { showErrorMessage } from "../../store/slices/alerts";
import { useIsPWA } from "hooks/useIsPWA";
import { IconBiometry } from "atoms/IconBiometry/IconBiometry";
import { authSelector } from "store/selectors";
import { useDispatch, useSelector } from "store";
import { CANCEL_MESSAGES } from "constants/biometry";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--brand-color-primary)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "var(--brand-color-primary)",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "var(--main-color-border-icon)",
    opacity: 1,
  },
}));

export const ProfileToggleBiometry = () => {
  const flag = Boolean(localStorage.getItem("useBiometry"));
  const dispatch = useDispatch();
  const isPWA = useIsPWA();
  const [isOn, setIsOn] = useState(flag);
  const { isLoading } = useSelector(authSelector);

  const handleBiometry = () => {
    if (isOn) {
      localStorage.removeItem("useBiometry");
      setIsOn(false);
    } else {
      dispatch(regBiometryToken(isPWA))
        .unwrap()
        .then((data) => {
          dispatch(getBiometryCredentials(data.registrationAddToken || ""))
            .unwrap()
            .then(async (data) => {
              const message = data.publicKeyCredentialCreationOptions;
              const optionsJson = parseCreationOptionsFromJSON({
                // @ts-ignore
                publicKey: message,
              });
              // @ts-ignore
              optionsJson.publicKey.extensions.appidExclude =
                // @ts-ignore
                optionsJson.publicKey.extensions.appidExclude.id;
              try {
                const credential = await create(optionsJson);
                const registrationToken = data.registrationId;
                //@ts-ignore
                dispatch(biometryRegConfirm({ registrationToken, credential }))
                  .unwrap()
                  .then(() => {
                    localStorage.setItem("useBiometry", "true");
                    dispatch(profileActions.setBiometryConnected("connected"));
                    setIsOn(true);
                  });
              } catch (err: any) {
                const errMsg = err instanceof Error ? err.message : "";
                if (CANCEL_MESSAGES.some((msg) => errMsg.includes(msg))) {
                  return;
                } else {
                  dispatch(checkIsBiometryKeyExist())
                    .unwrap()
                    .then((res) => {
                      if (CANCEL_MESSAGES.some((msg) => errMsg.includes(msg))) {
                        return;
                      } else if (res) {
                        localStorage.setItem("useBiometry", "true");
                        dispatch(
                          profileActions.setBiometryConnected("connected")
                        );
                        setIsOn(true);
                      } else {
                        dispatch(profileActions.setBiometryConnected("error"));
                        dispatch(
                          showErrorMessage({
                            errorTitle: "Ошибка",
                            errorMessage:
                              "При подключении входа по Face и Touch ID возникла ошибка",
                          })
                        );
                      }
                    });
                }
              }
            });
        });
    }
  };

  useEffect(() => {
    if (!flag && isOn) {
      setIsOn(false);
    }
  }, [isOn, flag]);

  return (
    <Box
      sx={{
        borderRadius: 16,
        backgroundColor: "var(--main-color-bg-widgets)",
        userSelect: "none",
        "-webkit-tap-highlight-color": "transparent",
      }}
      p={{ xs: 12, xl: 20 }}
      display="flex"
      gap={12}
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 36,
          height: 36,
          backgroundColor: "#FFFFFF",
        }}
      >
        <IconBiometry />
      </Box>
      <Typography variant="text_22" fontWeight={500} color="gray.b800" flex={1}>
        Авторизация по
        <br />
        биометрическим данным
      </Typography>
      <IOSSwitch
        sx={{ m: 1 }}
        disabled={isLoading}
        checked={isOn}
        onChange={handleBiometry}
      />
    </Box>
  );
};
