import { Box, Typography } from "@mui/material";

import { IconClose } from "atoms/IconClose/IconClose";
import ArrowLeft from "molecules/Modal/assets/ArrowLeft";

export const ModalHeader = ({
  title,
  subTitle = "",
  isMobile,
  isProfile,
  headerTop,
  buttonVisible = false,
  isLoginUpdated,
  isPasswordUpdated,
  onBack,
  handleLogout,
  titleMarginTop = "",
  titleStyle = {},
  isCenteredTitle = false,
}) => {
  const Title = () => (
    <Box
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "350px",
        marginTop: titleMarginTop,
        ...(isMobile &&
          isProfile && {
            width: "calc(100% - 29px)",
            textAlign: headerTop ? "left" : "center",
            display: "flex",
            justifyContent: headerTop ? "flex-start" : "center",
            alignItems: "center",
            gap: "5px",
          }),
      }}
    >
      {typeof title === "string" ? (
        <Typography
          sx={{
            color: "#454A3F",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: 500,
            lineHeight: isMobile ? "24px" : "normal",
          }}
          style={titleStyle}
        >
          {title}
        </Typography>
      ) : (
        title
      )}

      {subTitle && (
        <Typography
          noWrap
          sx={{
            color: "#7E7F7A",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          • {subTitle}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box
      display="flex"
      alignItems={headerTop ? "flex-start" : "center"}
      justifyContent={isCenteredTitle ? "center" : "space-between"}
      py={isMobile ? 28 : 32}
      px={isMobile ? 16 : 32}
    >
      {/* Desktop Header */}
      {!isMobile && (
        <>
          <Box display="flex" alignItems="center" gap={8}>
            {isProfile && buttonVisible && (
              <div
                onClick={
                  isLoginUpdated || isPasswordUpdated ? handleLogout : onBack
                }
                style={{
                  paddingLeft: "0px",
                  paddingRight: "10px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              >
                <ArrowLeft />
              </div>
            )}

            {/* Title */}
            <Title />
          </Box>
          {/* X button */}
          <Box
            sx={{
              cursor: "pointer",
              alignSelf: isProfile && !headerTop ? "center" : "flex-start",
              width: "24px",
              height: "24px",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={onBack}
          >
            <IconClose width={24} height={24} />
          </Box>
        </>
      )}

      {/* Mobile Header */}
      {isMobile && (
        <Box
          sx={{ width: "100%" }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={8}
        >
          <div
            onClick={
              isLoginUpdated || isPasswordUpdated ? handleLogout : onBack
            }
            style={{
              paddingLeft: "0px",
              paddingRight: "10px",
              marginTop: "5px",
              cursor: "pointer",
            }}
          >
            <ArrowLeft />
          </div>

          {/* Title */}
          <Title />
          <div />
        </Box>
      )}
    </Box>
  );
};
