import { useState, useCallback, useEffect } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  ChatAttachmentDto,
  ChatMessageDto,
  ChatMessageTypeEnum,
} from "api/notification";
import { useDispatch } from "store";
import { loadChatAttachment } from "store/slices/chat";
import { getDateWithTimezone } from "store/slices/chat/utils";
import theme from "theme";
import {
  StatusIconView,
  StatusIconSent,
  StatusIconError,
} from "widgets/ChatWidget/assets";
import { FilesMessages } from "../FilesMessages";
import { OperatorIcon } from "../OperatorIcon";
import { TextMessage } from "../TextMessage";
import { ChatMessageMenu } from "../ChatMessage/ChatMessageMenu";
import useLongPress from "hooks/useLongPress";
import { ReplyMessage } from "../ReplyMessage/ReplyMessage";

type MessageItemProps = {
  message: ChatMessageDto & { isError?: boolean };
  isWindow: boolean;
  onMsgSelect: () => void;
  selectedMsg: ChatMessageDto | null;
  useCompactPadding?: boolean;
  showIcon?: boolean;
};

const MessageItem = ({
  message,
  isWindow,
  useCompactPadding,
  onMsgSelect,
  selectedMsg,
  showIcon = false,
}: MessageItemProps) => {
  const dispatch = useDispatch();
  const {
    text,
    createdAt,
    user,
    read,
    attachments,
    isError,
    type,
    replyMessage,
  } = message;
  const [files, setFiles] = useState<ChatAttachmentDto[]>([]);

  const backspaceLongPress = useLongPress(onMsgSelect, 500);

  // считает количество строк в тексте
  const textLineCount = text ? text.split("\n").length : 1;

  const handleFileDownload = useCallback(
    (attachment: ChatAttachmentDto) => () => {
      dispatch(loadChatAttachment(attachment.id || ""))
        .unwrap()
        .then((res) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = `data:image/png;base64,${res}`;
          downloadLink.download = attachment.fileName || "File";
          downloadLink.click();
        });
    },
    [dispatch]
  );

  const hasRead = read ? <StatusIconView /> : <StatusIconSent />;
  const statusIcon = isError ? <StatusIconError /> : hasRead;
  const isMsgRead = !read && user;

  useEffect(() => {
    setFiles(attachments || []);
  }, [attachments]);

  if (
    (!text && attachments?.length === 0) ||
    type !== ChatMessageTypeEnum.Regular
  )
    return null;

  return (
    <Box
      sx={{
        backgroundColor: isMsgRead ? "rgba(120, 173, 105, 0.15)" : "",
        userSelect: "none",
        "-webkit-tap-highlight-color": "transparent",
      }}
    >
      {isMsgRead && (
        <Typography
          sx={{
            color: "#B5B5B5",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "center",
            py: "4px",
          }}
        >
          непрочитанное
        </Typography>
      )}
      <Box
        justifyContent={!user ? "flex-end" : "flex-start"}
        display="flex"
        gap={8}
        marginBottom={12}
        {...(useCompactPadding && {
          padding: { xs: "0 16px", lg: isWindow ? "0 16px" : "0 32px" },
        })}
      >
        {user && showIcon && <OperatorIcon />}
        <Box
          display={!user ? "flex" : "block"}
          alignItems={!user ? "flex-end" : "initial"}
          data-id={`message-box-${message.id}`}
        >
          {user && (
            <Typography
              mb={4}
              color={theme.palette.blue.b400}
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.036px",
                marginLeft: "24px",
              }}
            >
              {user}
            </Typography>
          )}

          <Box
            sx={{
              backgroundColor: user
                ? "#E3EADB"
                : "var(--main-color-bg-secondary)",
              borderRadius: "20px",
              padding: "8px 16px 12px 16px",
              cursor: "pointer",
              position: "relative",
              zIndex: selectedMsg?.id === message.id ? 199 : 0,
            }}
            {...backspaceLongPress}
            maxWidth={282}
          >
            {replyMessage && (
              <ReplyMessage
                isClientMessage={replyMessage?.isClientMessage}
                text={replyMessage?.text}
              />
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: textLineCount > 1 ? "flex-end" : "center",
              }}
              gap={8}
              mr={4}
            >
              {/* text and files */}
              <Box
                gap={text && files.length > 0 ? 8 : 0}
                display="flex"
                flexDirection="column"
                id={message.id}
              >
                {text && <TextMessage text={text} user={user} />}

                <FilesMessages
                  files={files}
                  onClick={handleFileDownload}
                  user={user}
                />
              </Box>

              {/* time & status */}
              <Box
                display="flex"
                alignItems="center"
                height="fit-content"
                gap={4}
                alignSelf={files.length > 0 ? "flex-end" : "center"}
              >
                <Typography
                  display="flex"
                  alignSelf="flex-end"
                  typography="text_0"
                  fontWeight={600}
                  color={theme.primary.main.gray["300"]}
                >
                  {getDateWithTimezone(createdAt)}
                </Typography>
                {!user && (
                  <Box display="flex" alignItems="flex-end">
                    {statusIcon}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageItem;
