import React from "react";
import Box from "@mui/material/Box";

interface OverlayProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  zIndex?: number;
  style?: React.CSSProperties;
}

export const Overlay: React.FC<OverlayProps> = ({
  onClick,
  zIndex = 0,
  style,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        inset: 0,
        background: "rgba(69, 74, 63, 0.50)",
        backdropFilter: "blur(4px)",
        zIndex: zIndex,
        ...style,
      }}
      onClick={onClick}
    />
  );
};
