import Box from "@mui/material/Box";
import { InputText } from "components";
import { Controller, useForm } from "react-hook-form";
import { Screens } from "./models";
import { updateLimits } from "store/slices/limitsSlice";
import {
  CardLimitConfirmResponse,
  CardLimitUpdateConfirmRequest,
  LimitType,
} from "api/account";
import { formatNumber } from "utils/formatCurrency";
import { useCallback, useMemo } from "react";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "store";
import { Button } from "components/Button";
import { rusSubtitle } from "utils/detectLimit";
import styles from "./styles.module.css";

type Props = {
  onClick: (nextPage: Screens) => void;
  nextScreen: Screens;
  limitItem: CardLimitConfirmResponse;
};

export const AddLimit = ({ onClick, nextScreen, limitItem }: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const {
    cards: {
      account: { currency },
      allCards,
      chosenCardIndex,
    },
    limits: { isLoading },
  } = useSelector((state) => state);

  const activeCard = allCards[chosenCardIndex || 0];
  const maxLimit = limitItem.readOnly
    ? limitItem.limitSumAmount
    : limitItem.maxAvailableLimit;

  const formatInput = useCallback((value: string): string => {
    return value.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }, []);

  const formatValue = useCallback(
    (value: string | number, sign: string = "") => {
      if (value && !String(value).includes(sign)) {
        return `${value} ${sign}`;
      }
      return value;
    },
    []
  );

  const defaultValue = useMemo(() => {
    const limitSum = String(limitItem.limitSumAmount);
    const formatted = formatInput(limitSum);
    return formatValue(formatted, currency?.sign);
  }, [formatInput, formatValue, limitItem.limitSumAmount, currency]);

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{ limit: string | number }>({
    mode: "onChange",
    defaultValues: {
      limit: defaultValue,
    },
  });
  const limit = watch("limit");

  const makeNum = (value: string | number) =>
    +String(value).replace(/[^0-9]/g, "");

  const handleClick = (newValueLimit?: number) => {
    const limitNum = makeNum(limit);
    const payload = {
      cardId: activeCard.cardId as string,
      limitId: limitItem.limitId,
      limitType: LimitType.Daily,
      limitSumAmount: newValueLimit || +limitNum,
    };
    dispatch(updateLimits(payload))
      .unwrap()
      .then((res: CardLimitUpdateConfirmRequest) => {
        if (res.updateLimitToken) {
          onClick(nextScreen);
        }
      })
      .catch((err: any) => {
        onClick("unsuccess");
        return err;
      });
  };

  const sameLimits = makeNum(limit) === Number(limitItem?.limitSumAmount);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        width={`calc(100% - ${isMobile ? 40 : 64}px)`}
        height="100%"
        margin={`${isMobile ? 0 : 32}px auto`}
        mt={0}
      >
        <p className={styles.title}>{limitItem.limitName}</p>
        <p className={styles.subTitle}>{limitItem.limitDescription}</p>
        <p className={styles.subDescription}>
          {limitItem.limitType && rusSubtitle(limitItem.limitType)}
        </p>
        <Box display="flex" flexDirection="column">
          <p className={styles.limit}>
            Максимально{" "}
            {formatNumber(Number(maxLimit) || 0, false, currency?.sign)}
          </p>
          <Controller
            control={control}
            name="limit"
            rules={{
              validate: (value) => {
                const valueNum = makeNum(value);
                return (
                  +valueNum <= (Number(maxLimit) || 0) ||
                  `Максимальный лимит ${formatNumber(
                    Number(maxLimit) || 0,
                    false,
                    currency?.sign
                  )}`
                );
              },
            }}
            render={({ field }) => (
              <InputText
                onChange={(e) => {
                  const value = e.target.value.length
                    ? makeNum(e.target.value).toString()
                    : e.target.value;

                  const formatted = formatInput(value);
                  field.onChange(formatted);
                }}
                onBlur={() => {
                  const formatted = formatValue(limit, currency?.sign);
                  field.onChange(formatted);
                }}
                value={limit}
                disabled={limitItem.readOnly}
                placeholder={`0 ${currency?.sign}`}
                hint={errors.limit?.message}
                error={!!errors.limit}
                className={styles.input}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            position: isMobile ? "fixed" : "static",
            bottom: 50,
            width: isMobile ? "calc(100% - 42px)" : "auto",
          }}
        >
          <Button
            disabled={!limit || !!errors.limit || sameLimits}
            variant="primary"
            onClick={handleClick}
            style={{ marginTop: "40px", marginBottom: "10px" }}
            isLoading={isLoading}
            title="Установить лимит"
          />
        </Box>
      </Box>
    </Box>
  );
};
